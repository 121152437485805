import axios from "axios"

import { sendEmail } from "./sendEmail"
import { sendMessage } from "./telerivet"
import { sendToZendesk, generateTemplate } from "./zendeskService"
import { isPdf } from "../components/Upload/services/uploadDocuments"
import { addAddress } from "../components/Profile/services/addresses"
import MEDICINE_TAGS from "../components/Epharmacy/utils/zendeskMedicineTags.json"

import { GATSBY_SUBMIT_ORDER_REQUEST_WEBHOOK } from "gatsby-env-variables"
import { isTest } from "./isTest"

const generateTags = (flow, medicines, patients, route) => {
  const templateTags = ["order", process.env.GATSBY_ENV]
  let subject, userConfirmationSubject
  if (flow.includes("epharmacy")) {
    subject = "Order from"
    userConfirmationSubject = "Your CarePlus Order"
    medicines.map((medicine) =>
      templateTags.push(MEDICINE_TAGS[medicine?.productTitle])
    )
    patients.map((patient) =>
      patient?.medicines.map((medicine) =>
        templateTags.push(MEDICINE_TAGS[medicine?.productTitle])
      )
    )
  }
  if (flow.includes("refill")) {
    subject = "Refill Form from"
    userConfirmationSubject = "Your CarePlus Order"
    templateTags.push("refill")
  }
  if (route?.includes("special-order")) templateTags.push("special_order")
  if (isTest()) {
    templateTags.push("test")
  }
  return { tags: templateTags, subject, userConfirmationSubject }
}

export const sendRequest = async ({
  flow, // process ? flexmed or epharmacy
  details, // state
  fileArray, // list of files
  templateConfirmation, // email template
  templateZendesk, // zendesk template
  templateTextMessage, // text message template
  callback, // success func
  route,
  products,
  deliveryFee,
  medicineBody,
  errorCallback,
}) => {
  let module = flow
  if (module === "refill") module = "epharmacy"
  let templateObjects = {
    ...details[module],
    adherenceLikert: details.adherenceLikert,
  }
  const { emailAddress } = templateObjects
  const { tags, subject, userConfirmationSubject } = generateTags(
    flow,
    templateObjects?.medicines || [],
    templateObjects?.patients || [],
    route
  )
  templateObjects = {
    ...templateObjects,
    files: fileArray,
    products: products?.allProducts?.nodes || [],
    deliveryFee,
    medicineBody,
  }
  const saveOverrideAddress = details?.epharmacy?.saveOverrideAddress
  const deliveryAddress = details?.epharmacy?.deliveryAddress

  try {
    const requestTemplate = generateTemplate(
      subject,
      emailAddress || null,
      templateZendesk,
      templateObjects,
      tags,
      `${
        templateObjects?.firstName
          ? `${templateObjects?.firstName} ${templateObjects?.lastName}`
          : templateObjects?.doctorAccountName
      }`
    )

    if (saveOverrideAddress)
      addAddress({
        values: {
          patientAddress: deliveryAddress,
        },
      })

    fileArray = fileArray.map((file) => {
      if (isPdf(file.path)) {
        return {
          ...file,
          filename: `${file.filename}.pdf`,
          name: `${file.name}.pdf`,
        }
      }
      return file
    })
    const response = await sendToZendesk(requestTemplate, fileArray)
    if (templateConfirmation)
      await sendEmail({
        to: emailAddress,
        cc: process.env.GATSBY_AWS_SES_EMAIL_TO,
        from: process.env.GATSBY_AWS_SES_EMAIL_FROM,
        subject: `${userConfirmationSubject}`,
        html: templateConfirmation(
          templateObjects,
          fileArray,
          response.data.request.id
        ),
        bcc: process.env.GATSBY_AWS_SES_EMAIL_BCC,
      })
    if (templateTextMessage)
      await sendMessage({
        recipient: templateObjects?.mobileNumber,
        message: templateTextMessage({
          zendeskID: response.data.request.id,
          templateObjects,
        }),
        callback: () => callback(emailAddress),
      })
  } catch (error) {
    await axios.post(GATSBY_SUBMIT_ORDER_REQUEST_WEBHOOK, {
      ...templateObjects,
      error: error,
    })
    if (errorCallback) await errorCallback()
  }
}
